import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getInvoicesByIdOwnerFunc, unsubscribePaypalFunc } from '../states/invoiceState';
import { decodeTkn } from '../states/generalState';
import { jwtDecode } from 'jwt-decode';
import PackagesSubscription from './PackagesSubscription';
import { Spinner } from 'react-bootstrap';

const _Invoices = () => {

    const dispatch = useDispatch();
    const invoicesData = useSelector((state) => state.invoices.invoicesData);
    const isLoading = useSelector((state) => state.invoices.isLoading);


    useEffect(() => {
        dispatch(decodeTkn());
        const tkn = jwtDecode(localStorage.getItem("token"), process.env.JWT_SECRET);
        dispatch(getInvoicesByIdOwnerFunc({ idOwner: tkn.id, token: localStorage.getItem("token") }));
    }, [])



    return (
        <div>
            <PackagesSubscription />

            <div className='pt-5'>
                <h1 className='fw-light text-center pb-3'>Invoices</h1>
                {
                    isLoading ?
                        <div className='mt-5 d-flex justify-content-center'>
                            <Spinner className='fw-light' variant='secondary' animation='border' />
                        </div>
                        :
                        <div>
                            {
                                !invoicesData.length && <h4 className='fw-light text-center'>No invoices available</h4>
                            }
                            {
                                invoicesData.length && invoicesData.map((el, index) => {
                                    return (
                                        <div className='border p-3 m-1 mx-2 bg-light d-flex justify-content-between align-items-center gap-3' key={`invoices1-${index}`}>
                                            <h5 className='fw-light'>{el.description}</h5>
                                            <div>{el.netAmount} {el.currency}</div>
                                            <h5><i className={`bi ${el.method === "PayPal" ? "bi-paypal myPaypalColor" : "bi-stripe myStripeColor"}`}></i></h5>
                                            <div>{el.date.split("T")[0].split("-").reverse().join("/")}</div>
                                            <h3><a href={el.invoiceUrl?el.invoiceUrl:""}><i className="bi bi-cloud-arrow-down-fill text-secondary myCursor" ></i></a></h3>
                                        </div>
                                    )
                                })
                            }
                        </div>
                }
            </div>
        </div>
    )
}

export default _Invoices