import React from 'react';
import _Store from '../components/_Store';

const StorePage = () => {
  return (
    <>
      <_Store />
    </>
  )
}

export default StorePage